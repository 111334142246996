<template>
  <v-list-group
    :color="dark_gradiant ? 'white' : undefined"
    :group="group"
    :prepend-icon="item.icon"
    eager
    v-bind="$attrs"
  >
    <template v-slot:activator>
      <v-list-item-icon
        v-if="!item.icon && !item.avatar"
        class="text-caption text-uppercase text-center my-2 align-self-center"
        style="margin-top: 14px"
      >
        {{ title }}
      </v-list-item-icon>

      <v-list-item-avatar v-if="item.avatar">
        <v-img :src="item.avatar" />
      </v-list-item-avatar>

      <v-list-item-content v-if="item.title">
        <v-list-item-title
          v-text="
            item.title.toUpperCase().slice(0, 1) +
              item.title.toLowerCase().slice(1)
          "
        />
      </v-list-item-content>
    </template>

    <template v-for="(child, i) in item.items">
      <template
        v-if="check_role(child)"
      >
        <default-list-group
          v-if="child.items"
          :key="`sub-group-${i}`"
          :item="child"
        />

        <default-list-item
          v-if="!child.items"
          :key="`child-${i}`"
          :item="child"
        />
      </template>
    </template>
  </v-list-group>
</template>

<script>
// Utilities
import { get } from "vuex-pathify";

export default {
  name: "DefaultListGroup",

  components: {
    DefaultListItem: () => import("./ListItem")
  },

  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    user: get("user/user"),
    dark_gradiant: get("user/dark_gradiant"),
    group() {
      return this.genGroup(this.item.items);
    },
    title() {
      const matches = this.item.title.match(/\b(\w)/g);
      return matches.join("");
    }
  },

  methods: {
    check_role(item) {
      if (item.roles) {
        if (item.roles?.allow) {
          return item.roles.allow.some(role => this.user.role == role);
        } else if (item.roles?.reject) {
          return !item.roles.reject.some(role => this.user.role == role);
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    genGroup(items) {
      return items
        .reduce((acc, cur) => {
          if (!cur.to) return acc;

          acc.push(cur.items ? this.genGroup(cur.items) : cur.to.slice(1, -1));

          return acc;
        }, [])
        .join("|");
    }
  }
};
</script>
